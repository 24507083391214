import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { uploadFilesToCloudflare, deleteFileFromCloudflare } from '../utils/cloudflare';
import { XCircleIcon, TrashIcon } from '@heroicons/react/20/solid';

const EditPost = ({ post, setEditingPost, fetchPosts }) => {
  const [title, setTitle] = useState(post.title);
  const [content, setContent] = useState(post.content);
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [category, setCategory] = useState(post.category);
  const quillRef = useRef(null);

  useEffect(() => {
    fetchMediaFiles();
  }, []);

  const fetchMediaFiles = async () => {
    const { data: mediaFiles, error } = await supabase
      .from('media')
      .select('*')
      .eq('post_id', post.id);

    if (error) {
      console.error('Error fetching media files:', error);
    } else {
      setExistingFiles(mediaFiles);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  const handleFileDelete = async (fileKey, mediaId) => {
    try {
      await deleteFileFromCloudflare(fileKey);
      await supabase.from('media').delete().eq('id', mediaId);
      setExistingFiles(existingFiles.filter(file => JSON.parse(file.url).key !== fileKey));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { error: postError } = await supabase
        .from('posts')
        .update({ title, content, category })
        .eq('id', post.id);

      if (postError) {
        console.error('Error updating post:', postError);
        return;
      }

      if (files.length > 0) {
        const uploadedFiles = await uploadFilesToCloudflare(files);

        const mediaData = uploadedFiles.map(file => ({
          post_id: post.id,
          url: JSON.stringify({ originalName: file.originalName, key: file.key, url: file.url })
        }));

        const { error: mediaError } = await supabase
          .from('media')
          .insert(mediaData);

        if (mediaError) {
          console.error('Error inserting media data:', mediaError);
          return;
        }

        setExistingFiles([...existingFiles, ...mediaData]);
      }

      setEditingPost(false);
      fetchPosts();
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleDeletePost = async () => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta publicación?');
    if (!confirmDelete) return;

    try {
      // Eliminar archivos asociados de Cloudflare
      for (let file of existingFiles) {
        const fileInfo = JSON.parse(file.url);
        await deleteFileFromCloudflare(fileInfo.key);
      }

      // Eliminar archivos asociados de la base de datos
      await supabase.from('media').delete().eq('post_id', post.id);

      // Eliminar la publicación
      const { error: deleteError } = await supabase
        .from('posts')
        .delete()
        .eq('id', post.id);

      if (deleteError) {
        console.error('Error deleting post:', deleteError);
        return;
      }

      setEditingPost(false);
      fetchPosts();
      window.location.reload(); // Hot refresh
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  const formats = [
    'header', 'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video'
  ];

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-gray-800 rounded-lg shadow-md">
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Título</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-2"
          required
        />
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Contenido</label>
        <ReactQuill
          ref={quillRef}
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
          className="bg-gray-700 text-white rounded-md"
        />
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Categoría</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-2"
        >
          <option value="manuales">Manuales</option>
          <option value="utilidades">Utilidades</option>
          <option value="noticias">Noticias</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Archivos Existentes</label>
        <ul className="space-y-1">
          {existingFiles.map((file, index) => {
            const fileInfo = JSON.parse(file.url);
            return (
              <li key={index} className="flex justify-between items-center bg-gray-700 p-2 rounded-md">
                <a href={fileInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-300">
                  {fileInfo.originalName}
                </a>
                <XCircleIcon
                  className="h-6 w-6 text-red-500 cursor-pointer"
                  onClick={() => handleFileDelete(fileInfo.key, file.id)}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Archivos Adicionales</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2"
        />
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleDeletePost}
          className="px-6 py-2 bg-red-500 text-white rounded-md shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-lg"
        >
          Eliminar Publicación
          <TrashIcon className="h-5 w-5 inline ml-2" />
        </button>
        <button
          type="submit"
          className="px-6 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-lg"
        >
          Guardar Cambios
        </button>
      </div>
    </form>
  );
};

export default EditPost;
