import React, { useEffect, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';

const ClientsDataGrid = () => {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const userRole = sessionStorage.getItem('userRole');

  useEffect(() => {
    fetchClientes();
  }, []);

  useEffect(() => {
    setFilteredClientes(
      clientes.filter(cliente => 
        cliente.nombre.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, clientes]);

  const fetchClientes = async () => {
    const { data, error } = await supabase.from('clientes').select('*');
    if (error) {
      console.error(error);
    } else {
      setClientes(data);
      setFilteredClientes(data);
    }
  };

  const handleViewDetails = (clienteId) => {
    navigate(`/client-details/${clienteId}`);
  };

  const handleCreateClient = () => {
    navigate('/CreateClient');
  };

  const handleEditClient = () => {
    if (selectedCliente) {
      navigate(`/edit-client/${selectedCliente.id}`);
    } else {
      alert('Seleccione un cliente para editar');
    }
  };

  const handleDeleteClient = async () => {
    if (selectedCliente) {
      const confirmed = window.confirm('¿Estás seguro de que deseas eliminar este cliente?');
      if (confirmed) {
        const { error } = await supabase.from('clientes').delete().eq('id', selectedCliente.id);
        if (error) {
          console.error(error);
        } else {
          fetchClientes(); // Refrescar la lista de clientes después de la eliminación
        }
      }
    } else {
      alert('Seleccione un cliente para eliminar');
    }
  };

  const columns = [
    { 
      headerName: 'Nombre del Cliente', 
      field: 'nombre', 
      cellStyle: { fontWeight: 'bold', fontSize: '16px', textTransform: 'uppercase' }, 
      resizable: true, 
      width: 300,
      filter: 'agTextColumnFilter',
      colId: 'nombre',
    },
    { 
      headerName: 'Delegación', 
      field: 'delegacion', 
      resizable: true, 
      width: 150,
      filter: 'agTextColumnFilter',
      colId: 'delegacion',
    },
    { 
      headerName: 'Código Postal', 
      field: 'codigoPostal', 
      resizable: true, 
      width: 150,
      filter: 'agTextColumnFilter',
      colId: 'codigoPostal',
    },
    { 
      headerName: 'Ciudad', 
      field: 'ciudad', 
      resizable: true, 
      filter: 'agTextColumnFilter',
      colId: 'ciudad',
    },
    { 
      headerName: 'Dirección del Centro', 
      field: 'direccion', 
      width: 330,
      resizable: true, 
      filter: 'agTextColumnFilter',
      colId: 'direccion',
    },
    {
      headerName: 'Fecha de Creación',
      field: 'createdAt',
      resizable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        if (!params.value) return '';
        const date = new Date(params.value);
        return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric'});
      },
      colId: 'createdAt',
    },
    { 
      headerName: 'Creado por',	 
      field: 'creadoPor', 
      width: 200,
      resizable: true, 
      filter: 'agTextColumnFilter',
      colId: 'creadoPor',
    },
    {
      headerName: '',
      field: 'actions',
      width: 200,
      filter: false,
      sortable: false,
      cellRenderer: (params) => (
        <div className='flex items-center justify-center space-x-2'>
          <button
            onClick={() => handleViewDetails(params.data.id)}
            className="bg-blue-500 text-white rounded hover:bg-blue-600 h-6 mt-2 px-2 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
          >
            <EyeIcon className="h-5 w-5 mr-2" />
            Ver Detalles
          </button>
        </div>
      ),
      colId: 'actions',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const updateColumnVisibility = () => {
      if (window.innerWidth < 640) { // sm breakpoint
        params.api.setColumnsVisible(['delegacion', 'codigoPostal', 'ciudad', 'direccion', 'createdAt', 'creadoPor'], false);
      } else {
        params.api.setColumnsVisible(['delegacion', 'codigoPostal', 'ciudad', 'direccion', 'createdAt', 'creadoPor'], true);
      }
      params.api.sizeColumnsToFit();
    };
    updateColumnVisibility();
    window.addEventListener('resize', updateColumnVisibility);
    return () => window.removeEventListener('resize', updateColumnVisibility);
  }, []);

  return (
    <div className='ag-theme-quartz-dark h-full flex-grow'>
      <div className="mb-4 flex justify-between">
      
        <div className='flex flex-row'>
          <button
            onClick={handleCreateClient}
            className="bg-blue-600 text-white p-2 rounded mt-2 mr-2 hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Crear Cliente
          </button>
          {userRole === 'Administrador' && (
            <button
              onClick={handleEditClient}
              className="bg-yellow-600 text-white p-2 rounded mt-2 mr-2 hover:bg-yellow-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Editar Cliente
            </button>
          )}
        </div>
            
        {userRole === 'Administrador' && (
          <button
            onClick={handleDeleteClient}
            className="bg-red-600 text-white p-2 rounded mt-2 hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Eliminar Cliente
          </button>
        )}
      </div>
      
      {/* Search bar for mobile */}
      <div className="sm:hidden mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar por nombre de cliente..."
          className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <AgGridReact
        rowData={filteredClientes}
        columnDefs={columns}
        pagination={false}
        paginationPageSize={20}
        rowSelection="single"
        onRowSelected={(event) => setSelectedCliente(event.api.getSelectedRows()[0])}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default ClientsDataGrid;
