import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon, FolderIcon, UserCircleIcon, ComputerDesktopIcon } from "@heroicons/react/20/solid";

export default function Home() {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem('userRole');

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <section className="px-4 py-5 bg-gray-900 flex min-h-screen flex-col items-center justify-center">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-semibold text-white mb-4">Inicio</h2>
        <p className="text-base text-gray-400 mb-8">
          Explora las opciones y funcionalidades disponibles.
        </p>
      </div>
      <div className="w-full">
        <div className={`grid gap-8 mt-1 sm:grid-cols-1 ${userRole === 'Administrador' ? 'lg:grid-cols-2' : 'md:grid-cols-2'} lg:grid-cols-2 xl:grid-cols-${userRole === 'Administrador' ? '4' : '2'} px-4 sm:px-8 lg:px-32 pb-24`}>
          <div className="relative flex flex-col bg-clip-border rounded-xl text-white shadow-md border border-blue-500 p-6">
            <div className="flex justify-center mb-4">
              <UserIcon className="h-10 w-10 text-blue-500" />
            </div>
            <h5 className="text-xl font-semibold text-white mb-2">Panel de Clientes</h5>
            <p className="text-sm text-gray-400 mb-4">
              Accede a la vista general de tu lista de clientes y gestiona sus datos.
            </p>
            <ul className="flex flex-col gap-3 mb-4">
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Vista general</p>
              </li>
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Configuraciones VPN</p>
              </li>
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Configuraciones DICOM</p>
              </li>
            </ul>
            <button
              onClick={() => handleNavigation('/Dashboard')}
              className="w-full py-2 px-4 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring focus:ring-blue-200 active:bg-blue-600"
            >
              Ver más
            </button>
          </div>
          <div className="relative flex flex-col bg-clip-border rounded-xl text-white shadow-md border border-blue-500 p-6">
            <div className="flex justify-center mb-4">
              <FolderIcon className="h-10 w-10 text-blue-500" />
            </div>
            <h5 className="text-xl font-semibold text-white mb-2">Documentación</h5>
            <p className="text-sm text-gray-400 mb-4">
              Consulta y gestiona toda tu documentación importante.
            </p>
            <ul className="flex flex-col gap-3 mb-4">
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Manuales de instalación</p>
              </li>
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Utilidades</p>
              </li>
              <li className="flex items-center gap-3 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                </svg>
                <p className="text-sm">Noticias</p>
              </li>
            </ul>
            <button
              onClick={() => handleNavigation('/Documentation')}
              className="w-full py-2 px-4 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring focus:ring-blue-200 active:bg-blue-600"
            >
              Ver más
            </button>
          </div>
          {userRole === 'Administrador' && (
            <div className="relative flex flex-col bg-clip-border rounded-xl text-white shadow-md border border-blue-500 p-6">
              <div className="flex justify-center mb-4">
                <UserCircleIcon className="h-10 w-10 text-blue-500" />
              </div>
              <h5 className="text-xl font-semibold text-white mb-2">Control de Cuentas</h5>
              <p className="text-sm text-gray-400 mb-4">
                Gestión de usuarios y roles dados de alta en el sistema.
              </p>
              <ul className="flex flex-col gap-3 mb-4">
                <li className="flex items-center gap-3 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                  </svg>
                  <p className="text-sm">Creación de usuarios</p>
                </li>
                <li className="flex items-center gap-3 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                  </svg>
                  <p className="text-sm">Asignación de roles</p>
                </li>
                <li className="flex items-center gap-3 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                  </svg>
                  <p className="text-sm">Administración de credenciales</p>
                </li>
              </ul>
              <button
                onClick={() => handleNavigation('/control-panel')}
                className="w-full py-2 px-4 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring focus:ring-blue-200 active:bg-blue-600"
              >
                Ver más
              </button>
            </div>
          )}
          {userRole === 'Administrador' && (
            <div className="relative flex flex-col bg-clip-border rounded-xl text-white shadow-md border border-blue-500 p-6">
              <div className="flex justify-center mb-4">
                <ComputerDesktopIcon className="h-10 w-10 text-blue-500" />
              </div>
              <h5 className="text-xl font-semibold text-white mb-2">Equipamiento</h5>
              <p className="text-sm text-gray-400 mb-4">
                Listado de equipos y dispositivos disponibles en la empresa.
              </p>
              <ul className="flex flex-col gap-3 mb-4">
                <li className="flex items-center gap-3 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                  </svg>
                  <p className="text-sm">Últimas versiones de firmware y software compatibles</p>
                </li>
                <li className="flex items-center gap-3 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-4 w-4 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                  </svg>
                  <p className="text-sm">Compatibilidades</p>
                </li>
              </ul>
              <button
                onClick={() => handleNavigation('/equipamiento')}
                className="w-full py-2 px-4 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring focus:ring-blue-200 active:bg-blue-600"
              >
                Ver más
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
