import React, { useEffect, useState, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { UserIcon, FolderIcon, ShieldCheckIcon, ArrowDownTrayIcon, CpuChipIcon, PhoneIcon, DocumentIcon } from '@heroicons/react/20/solid';
import { getSignedUrlForObject } from "../utils/awsClients";

const ClientDetails = () => {
  const { id } = useParams();
  const pdfRef = useRef();
  const [client, setClient] = useState({});
  const [vpnConfig, setVpnConfig] = useState([]);
  const [dicomConfig, setDicomConfig] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && id !== 'undefined') {
      fetchClientDetails();
    } else {
      setLoading(false); // Deja de cargar si no hay id
    }
  }, [id]);

  const fetchClientDetails = async () => {
    try {
      setLoading(true);
      const { data: clientData, error: clientError } = await supabase
        .from('clientes')
        .select('*')
        .eq('id', id)
        .single();

      if (clientError) throw clientError;

      const { data: vpnData, error: vpnError } = await supabase
        .from('vpn_config')
        .select('*')
        .eq('cliente_id', id);

      if (vpnError) throw vpnError;

      const { data: dicomData, error: dicomError } = await supabase
        .from('dicom_config')
        .select('*')
        .eq('cliente_id', id);

      if (dicomError) throw dicomError;

      const vpnDataWithSignedUrls = await Promise.all(vpnData.map(async (vpn) => {
        const documentos = vpn.documentos || [];
        const signedDocuments = await Promise.all(documentos.map(async (doc) => {
          const signedUrl = await getSignedUrlForObject(process.env.REACT_APP_R2_BUCKET_NAME, doc.key);
          return { ...doc, signedUrl };
        }));
        return { ...vpn, documentos: signedDocuments };
      }));

      const dicomDataWithSignedUrls = await Promise.all(dicomData.map(async (dicom) => {
        const documentos = dicom.documentos || [];
        const signedDocuments = await Promise.all(documentos.map(async (doc) => {
          const signedUrl = await getSignedUrlForObject(process.env.REACT_APP_R2_BUCKET_NAME, doc.key);
          return { ...doc, signedUrl };
        }));
        return { ...dicom, documentos: signedDocuments };
      }));

      setClient(clientData);
      setVpnConfig(vpnDataWithSignedUrls);
      setDicomConfig(dicomDataWithSignedUrls);
    } catch (error) {
      console.error('Error fetching client details:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderFiles = (files) => {
    return files.map((file, index) => (
      <li key={index} className="flex items-center">
        <a href={file.signedUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 flex-1">
          {file.originalName}
        </a>
        <a href={file.signedUrl} target="_blank" rel="noopener noreferrer" className="ml-2 text-gray-400 hover:text-gray-600">
          <ArrowDownTrayIcon className="w-5 h-5" />
        </a>
      </li>
    ));
  };

  // const generatePDF = async () => {
  //   try {
  //     const response = await fetch(`${process.env.PUBLIC_URL}/Informes/informe_cliente.html`);
  //     const html = await response.text();
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(html, 'text/html');
  
  //     // Populate the template with client data if the elements exist
  //     const populateElement = (id, text) => {
  //       const element = doc.getElementById(id);
  //       if (element) {
  //         element.textContent = text;
  //       }
  //     };
  
  //     populateElement('nombre', `Nombre: ${client.nombre}`);
  //     populateElement('delegacion', `Delegación: ${client.delegacion}`);
  //     populateElement('codigoPostal', `Código Postal: ${client.codigoPostal}`);
  //     populateElement('ciudad', `Ciudad: ${client.ciudad}`);
  //     populateElement('direccion', `Dirección: ${client.direccion}`);
  //     populateElement('comentarios', `Comentarios: ${client.comentarios}`);
  
  //     const contactList = doc.getElementById('contact-list');
  //     if (contactList && client.contacto) {
  //       client.contacto.forEach(contacto => {
  //         const li = doc.createElement('li');
  //         li.textContent = `${contacto.nombre} - ${contacto.email} - ${contacto.telefono}`;
  //         contactList.appendChild(li);
  //       });
  //     }
  
  //     const equiposList = doc.getElementById('equipos-list');
  //     if (equiposList && client.equipos) {
  //       client.equipos.forEach(equipo => {
  //         const li = doc.createElement('li');
  //         li.textContent = `${equipo.nombre} - ${equipo.numeroSerie}`;
  //         equiposList.appendChild(li);
  //       });
  //     }
  
  //     const vpnConfigText = doc.getElementById('vpn-config-text');
  //     if (vpnConfigText && vpnConfig[0]) {
  //       vpnConfigText.textContent = vpnConfig[0].configuracion;
  //     }
  
  //     const vpnDocuments = doc.getElementById('vpn-documents');
  //     if (vpnDocuments && vpnConfig[0] && vpnConfig[0].documentos) {
  //       vpnConfig[0].documentos.forEach(docItem => {
  //         const li = doc.createElement('li');
  //         li.textContent = docItem.originalName;
  //         vpnDocuments.appendChild(li);
  //       });
  //     }
  
  //     const dicomStorage = doc.getElementById('dicom-storage');
  //     const dicomWorklist = doc.getElementById('dicom-worklist');
  //     const dicomComments = doc.getElementById('dicom-comments');
  //     const dicomDocuments = doc.getElementById('dicom-documents');
  
  //     if (dicomConfig && dicomConfig.length > 0) {
  //       dicomConfig.forEach(dicom => {
  //         if (dicomStorage) {
  //           dicomStorage.innerHTML = `
  //             <h3>Storage</h3>
  //             <p>SCP AET: ${dicom.configuracion.scpAET}</p>
  //             <p>Dirección IP: ${dicom.configuracion.ipServidor}</p>
  //             <p>Puerto: ${dicom.configuracion.puertoStorage}</p>
  //           `;
  //           dicom.configuracion.scuAETs.forEach(scu => {
  //             const p = doc.createElement('p');
  //             p.textContent = `SCU AET: ${scu.aet} - Modalidad: ${scu.modalidad}`;
  //             dicomStorage.appendChild(p);
  //           });
  //         }
  
  //         if (dicomWorklist) {
  //           dicomWorklist.innerHTML = `
  //             <h3>Worklist</h3>
  //             <p>SCP AET: ${dicom.configuracion.scpAETWL}</p>
  //             <p>Dirección IP: ${dicom.configuracion.ipServidorWL}</p>
  //             <p>Puerto: ${dicom.configuracion.puertoWL}</p>
  //           `;
  //           dicom.configuracion.scuWLs.forEach(aet => {
  //             const p = doc.createElement('p');
  //             p.textContent = `SCU AET: ${aet}`;
  //             dicomWorklist.appendChild(p);
  //           });
  //         }
  
  //         if (dicomComments) {
  //           dicomComments.textContent = dicom.configuracion.comentarios;
  //         }
  
  //         if (dicomDocuments && dicom.documentos) {
  //           dicom.documentos.forEach(docItem => {
  //             const li = doc.createElement('li');
  //             li.textContent = docItem.originalName;
  //             dicomDocuments.appendChild(li);
  //           });
  //         }
  //       });
  //     }
  
  //     // Create a temporary container for the HTML
  //     const tempContainer = document.createElement('div');
  //     tempContainer.style.position = 'fixed';
  //     // tempContainer.style.top = '-10000px';
  //     tempContainer.style.backgroundColor = '#fff';
  //     document.body.appendChild(tempContainer);
  //     tempContainer.appendChild(doc.body);
  
  //     // Wait for next animation frame to ensure rendering
  //     requestAnimationFrame(async () => {
  //       // Capture the HTML content to canvas
  //       const canvas = await html2canvas(tempContainer, {
  //         scale: 2, // Increase scale for better quality
  //         useCORS: true, // Enable cross-origin resources
  //         logging: true, // Enable logging for troubleshooting
  //       });
  
  //       const imgData = canvas.toDataURL('image/png', 1.0);
  //       const pdf = new jsPDF('p', 'pt', 'a4');
  //       pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / 2, canvas.height / 2); // Adjust width and height to fit A4
  
  //       // Generate file name using client name
  //       // const fileName = `informe_cliente_${client.nombre.replace(/\s+/g, '_')}.pdf`;
  //       // Convert the PDF to a Blob and open it in a new tab
  //       const pdfBlob = pdf.output('blob');
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  
  //       const link = document.createElement('a');
  //       link.href = pdfUrl;
  //       link.target = '_blank';
  //       window.open(pdfUrl, '_blank');
  
  //       // Clean up the temporary container
  //       document.body.removeChild(tempContainer);
  //     });
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //   }
  // };

  const generatePDF = async () => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/Informes/informe_cliente.html`);
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Populate the template with client data if the elements exist
        const populateElement = (id, text) => {
            const element = doc.getElementById(id);
            if (element) {
                element.textContent = text;
            }
        };

        populateElement('nombre', client.nombre || '');
        populateElement('delegacion', client.delegacion || '');
        populateElement('codigoPostal', client.codigoPostal || '');
        populateElement('ciudad', client.ciudad || '');
        populateElement('direccion', client.direccion || '');
        populateElement('comentarios', client.comentarios || '');

        const contactList = doc.getElementById('contact-list');
        if (contactList && client.contacto) {
            client.contacto.forEach(contacto => {
                const li = doc.createElement('li');
                li.textContent = `${contacto.nombre || ''} - ${contacto.email || ''} - ${contacto.telefono || ''}`;
                contactList.appendChild(li);
            });
        }

        const equiposList = doc.getElementById('equipos-list');
        if (equiposList && client.equipos) {
            client.equipos.forEach(equipo => {
                const li = doc.createElement('li');
                li.textContent = `${equipo.nombre || ''} - ${equipo.numeroSerie || ''}`;
                equiposList.appendChild(li);
            });
        }

        const vpnConfigText = doc.getElementById('vpn-config-text');
        if (vpnConfigText && vpnConfig && vpnConfig.length > 0) {
            vpnConfigText.textContent = vpnConfig[0].configuracion || '';
        }

        const vpnDocuments = doc.getElementById('vpn-documents');
        if (vpnDocuments && vpnConfig && vpnConfig.length > 0 && vpnConfig[0].documentos) {
            vpnConfig[0].documentos.forEach(docItem => {
                const li = doc.createElement('li');
                const a = doc.createElement('a');
                a.href = docItem.signedUrl || '#';
                a.textContent = docItem.originalName || '';
                a.target = '_blank';
                li.appendChild(a);
                vpnDocuments.appendChild(li);
            });
        }

        const dicomStorage = doc.getElementById('dicom-storage');
        const dicomWorklist = doc.getElementById('dicom-worklist');
        const dicomComments = doc.getElementById('dicom-comments');
        const dicomDocuments = doc.getElementById('dicom-documents');

        if (dicomConfig && dicomConfig.length > 0) {
            dicomConfig.forEach(dicom => {
                if (dicomStorage) {
                    dicomStorage.innerHTML = `
                        <h3>Storage</h3>
                        <p>SCP AET: ${dicom.configuracion.scpAET || ''}</p>
                        <p>Dirección IP: ${dicom.configuracion.ipServidor || ''}</p>
                        <p>Puerto: ${dicom.configuracion.puertoStorage || ''}</p>
                    `;
                    dicom.configuracion.scuAETs.forEach(scu => {
                        const p = doc.createElement('p');
                        p.textContent = `SCU AET: ${scu.aet || ''} - Modalidad: ${scu.modalidad || ''}`;
                        dicomStorage.appendChild(p);
                    });
                }

                if (dicomWorklist) {
                    dicomWorklist.innerHTML = `
                        <h3>Worklist</h3>
                        <p>SCP AET: ${dicom.configuracion.scpAETWL || ''}</p>
                        <p>Dirección IP: ${dicom.configuracion.ipServidorWL || ''}</p>
                        <p>Puerto: ${dicom.configuracion.puertoWL || ''}</p>
                    `;
                    dicom.configuracion.scuWLs.forEach(aet => {
                        const p = doc.createElement('p');
                        p.textContent = `SCU AET: ${aet || ''}`;
                        dicomWorklist.appendChild(p);
                    });
                }

                if (dicomComments) {
                    dicomComments.textContent = dicom.configuracion.comentarios || '';
                }

                if (dicomDocuments && dicom.documentos) {
                    dicom.documentos.forEach(docItem => {
                        const li = doc.createElement('li');
                        const a = doc.createElement('a');
                        a.href = docItem.signedUrl || '#';
                        a.textContent = docItem.originalName || '';
                        a.target = '_blank';
                        li.appendChild(a);
                        dicomDocuments.appendChild(li);
                    });
                }
            });
        }

        // Get the modified HTML as a string
        const updatedHTML = doc.documentElement.outerHTML;

        // Create a new jsPDF instance
        const pdf = new jsPDF();

        // Use html2pdf.js to convert HTML to PDF
        pdf.html(updatedHTML, {
            callback: function (doc) {
                // Open the PDF in a new tab
                doc.save("informe_cliente.pdf");
            },
            x: 10,
            y: 10,
            width: 180,
            windowWidth: 800,
        });

    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};


  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  if (!client.nombre) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <p>Error: No se pudo cargar la información del cliente.</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 max-h-screen px-6 mb-10 text-white">
      {/* Barra de Navegación */}
      <nav className="flex mb-4" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <a
              href="/Home"
              className="inline-flex items-center text-sm font-medium text-gray-400 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Inicio
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="/Dashboard"
                className="ms-1 text-sm font-medium text-gray-400 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Clientes
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href={`/client-details/${id}`}
                className="ms-1 text-sm font-medium capitalize text-gray-400 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Ver detalles del cliente
              </a>
            </div>
          </li>
        </ol>
        
      </nav>
      <section className="text-white mb-5 flex flex-row ">
        <div className="sm:container">
          <div className="border-l-[5px] border-primary border-blue-600 pl-5">
            <h2 className="mb-2 text-2xl font-semibold text-dark dark:text-white">
              Detalles del Cliente
            </h2>
            <p className="text-sm font-medium text-body-color dark:text-dark-6">
              Accede a la vista detallada de tu cliente y gestiona sus datos.
            </p>
          </div>
        </div>
        <button
        onClick={generatePDF}
        className="bg-blue-500 text-white flex flex-row py-2 px-4 mt-5 rounded hover:bg-blue-600 transition duration-300"
      >
        <DocumentIcon className="w-5  mr-2" />
        Generar Informe
      </button>
      </section>
      <div ref={pdfRef}>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Columna 1: Información del Cliente */}
          <div className="bg-gray-800 space-y-4 p-6 rounded-lg shadow-lg">
            <div className="flex items-center mb-4">
              <UserIcon className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-bold">Información del Cliente</h2>
            </div>
            <p><strong>Nombre:</strong> {client.nombre}</p>
            <p><strong>Delegación:</strong> {client.delegacion}</p>
            <p><strong>Código Postal:</strong> {client.codigoPostal}</p>
            <p><strong>Ciudad:</strong> {client.ciudad}</p>
            <p><strong>Dirección:</strong> {client.direccion}</p>
            <p><strong>Comentarios:</strong> {client.comentarios}</p>

            <div className="flex items-center mt-6 mb-4">
              <PhoneIcon className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-bold">Contactos</h2>
            </div>
            {client.contacto && client.contacto.length > 0 ? (
              <ul>
                {client.contacto.map((contacto, index) => (
                  <li key={index}>{contacto.nombre} - {contacto.email} - {contacto.telefono}</li>
                ))}
              </ul>
            ) : (
              <p>No hay contactos asignados.</p>
            )}
          </div>

          {/* Columna 2: Equipos y Configuraciones VPN */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <div className="flex items-center mb-4">
              <CpuChipIcon className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-bold">Equipos Asignados</h2>
            </div>
            {client.equipos && client.equipos.length > 0 ? (
              <ul>
                {client.equipos.map((equipo, index) => (
                  <li key={index}>{equipo.nombre} - {equipo.numeroSerie}</li>
                ))}
              </ul>
            ) : (
              <p>No hay equipos asignados.</p>
            )}

            <div className="flex items-center mt-6 mb-4">
              <ShieldCheckIcon className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-bold">Configuraciones VPN</h2>
            </div>
            {vpnConfig.length > 0 ? (
              vpnConfig.map((vpn, index) => (
                <div key={index} className="mb-4">
                  <p className="mb-2"><strong>Configuración:</strong> <span className="text-gray-400 preformatted">{vpn.configuracion}</span></p>
                  <h3 className="text-lg font-bold mt-2">Documentos</h3>
                  <ul>
                    {renderFiles(vpn.documentos)}
                  </ul>
                </div>
              ))
            ) : (
              <p>No hay configuraciones VPN.</p>
            )}
          </div>

          {/* Columna 3: Configuraciones DICOM */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <div className="flex items-center mb-4">
              <FolderIcon className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-bold">Configuraciones DICOM</h2>
            </div>
            {dicomConfig.length > 0 ? (
              dicomConfig.map((dicom, index) => (
                <div key={index} className="mb-4">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className=''>
                      <h3 className=" text-3xl border-b-2 border-blue-600 font-extralight my-2">Storage</h3>
                      <p><strong>SCP AET:</strong> {dicom.configuracion.scpAET}</p>
                      <p><strong>Dirección IP:</strong> {dicom.configuracion.ipServidor}</p>
                      <p><strong>Puerto:</strong> {dicom.configuracion.puertoStorage}</p>
                      {dicom.configuracion.scuAETs.map((scu, scuIndex) => (
                        <div key={scuIndex}>
                          <p><strong>SCU AET:</strong> {scu.aet}</p>
                          <p><strong>Modalidad:</strong> {scu.modalidad}</p>
                        </div>
                      ))}
                    </div>
                    <div>
                      <h3 className="text-3xl border-b-2 border-blue-600 font-extralight my-2">Worklist</h3>
                      <p><strong>SCP AET:</strong> {dicom.configuracion.scpAETWL}</p>
                      <p><strong>Dirección IP:</strong> {dicom.configuracion.ipServidorWL}</p>
                      <p><strong>Puerto:</strong> {dicom.configuracion.puertoWL}</p>
                      {dicom.configuracion.scuWLs.map((aet, wlIndex) => (
                        <div key={wlIndex}>
                          <p><strong>SCU AET:</strong> {aet}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p><strong>Comentarios:</strong> {dicom.configuracion.comentarios}</p>
                  <h3 className="text-lg font-bold mt-2">Documentos</h3>
                  <ul>
                    {renderFiles(dicom.documentos)}
                  </ul>
                </div>
              ))
            ) : (
              <p>No hay configuraciones DICOM.</p>
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default ClientDetails;
