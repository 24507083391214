import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { PlusIcon, MagnifyingGlassIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import CreatePost from './CreatePost';
import PostView from './PostView';
import Loader from './Loader'; // Importa el componente Loader

const Index = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [creatingPost, setCreatingPost] = useState(false);
  const [isIndexVisible, setIsIndexVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const userRole = sessionStorage.getItem('userRole');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const { data, error } = await supabase
      .from('posts')
      .select(`
        *,
        logins ( nombre )
      `)
      .order('created_at', { ascending: false });
    if (error) {
      console.error(error);
    } else {
      setPosts(data);
      setFilteredPosts(data);
      const uniqueCategories = [...new Set(data.map(post => post.category))];
      setCategories(uniqueCategories);
      setLoading(false); // Termina la carga
    }
  };

  const handlePostClick = (postId) => {
    setSelectedPostId(postId);
    setCreatingPost(false);
    setIsIndexVisible(false);
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const handleCreatePostClick = () => {
    setCreatingPost(true);
    setIsIndexVisible(false);
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredPosts(posts.filter(post => post.title.toLowerCase().includes(query)));
  };

  return (
    <div className="flex min-h-screen text-white">
      {isIndexVisible && (
        <aside className="w-full lg:w-1/4 bg-gray-800 p-4 fixed inset-0 lg:relative lg:block z-10 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Índice</h2>
            {(userRole === 'Administrador' || userRole === 'Publicador') && (
            <button onClick={handleCreatePostClick} className="bg-blue-500 p-2 rounded-full">
              <PlusIcon className="h-6 w-6 text-white" />
            </button>
            )}
          </div>
          <div className="relative mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Buscar posts..."
              className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <MagnifyingGlassIcon className="w-5 h-5 absolute top-2 right-2 text-gray-400" />
          </div>
          {loading ? (
            <Loader /> // Muestra el loader mientras se cargan los posts
          ) : (
            categories.map(category => (
              <div key={category} className="mb-4">
                <h3 className="text-lg font-semibold capitalize">{category}</h3>
                <ul className="space-y-2">
                  {filteredPosts.filter(post => post.category === category).map(post => (
                    <li
                      key={post.id}
                      onClick={() => handlePostClick(post.id)}
                      className={`cursor-pointer p-2 rounded-md ${
                        selectedPostId === post.id ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          {post.title}
                          <span className="text-sm text-gray-400 ml-2">{new Date(post.created_at).toLocaleDateString()}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </aside>
      )}

      <main className="w-full lg:w-3/4 p-8 bg-gray-900 relative z-0 overflow-y-auto">
        {!isIndexVisible && (
          <button
            className=" bg-blue-500 p-2 rounded-full fixed top-4 left-4"
            onClick={() => setIsIndexVisible(true)}
          >
            <ChevronDownIcon className={`h-6 w-6 text-white transform ${isIndexVisible ? 'rotate-180' : ''}`} />
          </button>
        )}
        {creatingPost ? (
          <CreatePost setCreatingPost={setCreatingPost} fetchPosts={fetchPosts} />
        ) : (
          selectedPostId && (
            <PostView postId={selectedPostId} fetchPosts={fetchPosts} userRole={userRole} />
          )
        )}
      </main>
    </div>
  );
};

export default Index;
