import React from 'react';
import TopBar from '../Components/TopBar';
import Footer from '../Components/Footer';

const Layout = ({ children, userName }) => {
  return (
    <main className="flex flex-col min-h-screen bg-gray-900">
      <TopBar userName={userName} />
      <div >
        {children}
      </div>
      <Footer />
    </main>
  );
};

export default Layout;
