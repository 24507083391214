import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import Home from './Pages/Home';
import ClientDashboard from './Pages/ClientsDashboard';
import PrivateRoute from './Components/PrivateRoute';
import CreateClient from './Components/CreateClient';
import EditClient from './Components/EditClient';
import Layout from './Layout/Layout';
import ClientDetails from './Components/ClientDetails';
import Documentation from './Pages/Documentation';
import ChangePassword from './Components/ChangePassword';
import ControlCuentas from './Pages/ControlCuentas';
import CreateUser from './Components/CreateUser';
import EditUser from './Components/EditUser';
import Equipamiento from './Pages/Equipamiento';
import ResetPassword from './Components/ResetPassword';

function App() {
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [firstLogin, setFirstLogin] = useState(false);

  useEffect(() => {
    const storedUserName = sessionStorage.getItem('userName');
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserName) {
      setUserName(storedUserName);
      setUserId(storedUserId);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setUserName={setUserName} setUserId={setUserId} setFirstLogin={setFirstLogin} />} />
        <Route 
          path="/Home" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <Home />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/Dashboard" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <ClientDashboard />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/CreateClient" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <CreateClient />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/Edit-client/:id" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <EditClient />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/client-details/:id" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <ClientDetails />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/Documentation" 
          element={
            <PrivateRoute>
              <Layout userName={userName}>
                <Documentation />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/change-password" 
          element={
            <PrivateRoute>
              <ChangePassword userId={userId} />
            </PrivateRoute>
          }
        />
        <Route 
          path="/control-panel" 
          element={
            <PrivateRoute adminOnly={true}>
              <Layout userName={userName}>
                <ControlCuentas />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route 
          path="/CreateUser" 
          element={
            <PrivateRoute adminOnly={true}>
              <Layout userName={userName}>
                <CreateUser />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/edit-user/:id" 
          element={
            <PrivateRoute adminOnly={true}>
              <Layout userName={userName}>
                <EditUser />
              </Layout>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/equipamiento" 
          element={
            <PrivateRoute adminOnly={false}>
              <Layout userName={userName}>
                <Equipamiento />
              </Layout>
            </PrivateRoute>
          } 
        />
                <Route path="/reset-password/:token" element={<ResetPassword />} />

      </Routes>
    </Router>
  );
}

export default App;
