import React from 'react';
import { useNavigate } from 'react-router-dom';
import {  ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline"; // VPN-like icon

export default function TopBar({ userName = 'Unknown Unknown', userImage = 'path-to-user-image' }) {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const userRole = sessionStorage.getItem('userRole');
  const handleLogout = () => {
    // Clear any authentication tokens or user data
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userName');
    // Navigate to login page
    navigate('/');
  };

  return (
    <header className="w-full flex items-center justify-end text-white px-5 mb-6 mt-5">
      <div className="flex items-center space-x-4">
        <div className="flex items-center gap-5">
          <div className="text-sm font-semibold flex flex-col ml-2 text-center text-gray-100 ">{userName}  <span className=' font-thin text-xs text-blue-400'> {userRole}</span></div>
          <div className="h-10 w-10 rounded-full cursor-pointer bg-gray-200 border-2 border-blue-400 overflow-hidden">
            <img src='favicon.ico' alt="User" className="h-full w-full object-cover" /> 
            
          </div>
        </div>
        <div
          className="flex items-center justify-center rounded-lg cursor-pointer hover:bg-gray-700 hover:text-blue-400 transition duration-300 ease-linear p-2"
          onClick={handleLogout}
        >
          <ArrowLeftOnRectangleIcon className="h-6 w-6 text-blue-500" />
        </div>
      </div>
    </header>
  );
}
