import { S3Client, PutObjectCommand, DeleteObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { v4 as uuidv4 } from 'uuid';

const s3Client = new S3Client({
  region: 'auto',
  endpoint: `https://${process.env.REACT_APP_R2_ACCOUNT_ID}.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: process.env.REACT_APP_R2_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_R2_SECRET_KEY_ID
  }
});

export const uploadFilesToCloudflare = async (files) => {

  const uploadedFiles = await Promise.all(files.map(async (file) => {
    const fileKey = `${uuidv4()}_${file.name}`;
    const command = new PutObjectCommand({
      Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
      Key: fileKey,
      Body: file,
      ContentType: file.type
    });

    try {
      await s3Client.send(command);
      return {
        originalName: file.name,
        key: fileKey,
        url: `https://${process.env.REACT_APP_R2_ACCOUNT_ID}.r2.cloudflarestorage.com/${process.env.REACT_APP_R2_BUCKET_NAME}/${fileKey}`
      };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }));

  return uploadedFiles;
};

export const deleteFileFromCloudflare = async (fileKey) => {
  const command = new DeleteObjectCommand({
    Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
    Key: fileKey
  });

  await s3Client.send(command);
};

export const getSignedUrlForObject = async (key) => {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
    Key: key,
  });

  try {
    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    console.error('Error getting signed URL:', error);
    throw error;
  }
};