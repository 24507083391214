import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const ChangePassword = ({ userId }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== repeatNewPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    if (!validatePassword(newPassword)) {
      setError('La nueva contraseña debe tener al menos 8 caracteres, una mayúscula y un signo.');
      return;
    }

    const { data: userData, error: userError } = await supabase
      .from('logins')
      .select('*')
      .eq('id', userId)
      .single();

    if (userError || !userData) {
      setError('Error al obtener los datos del usuario.');
      return;
    }

    if (userData.password !== oldPassword) {
      setError('La contraseña antigua es incorrecta.');
      return;
    }

    const { error: updateError } = await supabase
      .from('logins')
      .update({ password: newPassword, ultima_vez_login: new Date() })
      .eq('id', userId);

    if (updateError) {
      setError('Error al cambiar la contraseña.');
      return;
    }

    setSuccess('Contraseña cambiada exitosamente.');
    setTimeout(() => {
      navigate('/Home');
    }, 2000);
  };

  const validatePassword = (password) => {
    // Expresión regular para validar una contraseña con al menos 8 caracteres,
    // una letra mayúscula y un carácter especial
    const regex = /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.{8,})/;
    return regex.test(password);
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-900 px-6 py-24 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-bold text-white">Cambiar Contraseña</h2>
        <p className="mt-2 text-center text-sm text-gray-400">
          Este es tu primer inicio de sesión, por favor cambia tu contraseña.
        </p>
        <div className="mt-8 bg-gray-800 py-8 px-6 shadow rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleChangePassword}>
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-300">Contraseña Antigua</label>
              <div className="mt-1">
                <input
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-300">Nueva Contraseña</label>
              <div className="mt-1">
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="repeatNewPassword" className="block text-sm font-medium text-gray-300">Repetir Nueva Contraseña</label>
              <div className="mt-1">
                <input
                  id="repeatNewPassword"
                  name="repeatNewPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={repeatNewPassword}
                  onChange={(e) => setRepeatNewPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {error && (
              <div className="text-red-600 text-sm">{error}</div>
            )}
            {success && (
              <div className="text-green-600 text-sm">{success}</div>
            )}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Cambiar Contraseña
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
