import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import $ from 'jquery';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Footer from './Footer';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Login({ setUserName, setUserId, setFirstLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [recoverEmail, setRecoverEmail] = useState('');
  const [recoverMessage, setRecoverMessage] = useState('');
  const [failedAttempts, setFailedAttempts] = useState(0);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const { data: loginData, error: loginError } = await supabase
      .from('logins')
      .select('*')
      .eq('email', email)
      .single();

    if (loginError || !loginData) {
      setError('Usuario no encontrado.');
      return;
    }

    if (loginData.password !== password) {
      setFailedAttempts(failedAttempts + 1);
      if (failedAttempts + 1 >= 3) {
        setShowModal(true);
      } else {
        setError('Contraseña incorrecta.');
      }
      return;
    }

    // Restablecer intentos fallidos al iniciar sesión correctamente
    setFailedAttempts(0);

    sessionStorage.setItem('isAuthenticated', 'true');
    sessionStorage.setItem('userName', loginData.nombre);
    sessionStorage.setItem('userId', loginData.id);
    sessionStorage.setItem('userRole', loginData.rol);
    if (rememberMe) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userName', loginData.nombre);
      localStorage.setItem('userId', loginData.id);
      localStorage.setItem('userRole', loginData.rol);
    }
    setUserName(loginData.nombre);
    setUserId(loginData.id);

    if (loginData.ultima_vez_login === null) {
      setFirstLogin(true);
      navigate('/change-password');
    } else {
      const { error: updateError } = await supabase
        .from('logins')
        .update({ ultima_vez_login: new Date() })
        .eq('email', email);

      if (updateError) {
        setError('Error al actualizar el último acceso.');
        return;
      }
      navigate('/Home');
    }
  };

  const handleRecoverPassword = (e) => {
    e.preventDefault();

    const token = Math.random().toString(36).substring(2);

    const templateParams = {
      user_email: recoverEmail,
      reset_link: `https://testopcon.pages.dev/reset-password/${token}`,
    };

    const data = {
      service_id: 'service_boge6jr',
      template_id: 'template_tddkyh8',
      user_id: 'ZPWW3G4uAD0scU0TE',
      template_params: templateParams,
    };

    $.ajax('https://api.emailjs.com/api/v1.0/email/send', {
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
    })
      .done(function () {
        setRecoverMessage('Correo de recuperación enviado.');

        // Obtener la hora actual en la zona horaria de España
        const nowInSpain = dayjs().tz('Europe/Madrid').format();

        // Guarda el token en la base de datos
        supabase
          .from('logins')
          .update({
            reset_token: token,
            reset_token_expiry: nowInSpain, // Guardar la fecha y hora de expiración ajustada a la zona horaria de España
          })
          .eq('email', recoverEmail)
          .then(({ error }) => {
            if (error) {
              console.log('Error al guardar el token', error);
            }
          });
      })
      .fail(function (error) {
        setRecoverMessage('Error al enviar el correo de recuperación.');
        console.log('Oops... ' + JSON.stringify(error));
      });
  };

  const formAnimation = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,-50px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
  });

  const logoAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
    delay: 200,
  });

  return (
    <div className="flex h-screen flex-1 flex-col justify-center items-center justify-items-center px-6 py-52 lg:px-8">
      <animated.div style={logoAnimation} className="sm:mx-auto sm:w-full sm:max-w-sm">
        <svg
          enableBackground="new 0 0 2485.7 399.9"
          viewBox="0 0 2485.7 399.9"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-auto max-w-xs sm:max-w-sm"
        >
          <path d="m359.8 128c-7.7 8.9-15.5 17.9-22.6 26.8 29.8-63.1 35.7-117.9 11.3-141.8-39.3-38.7-149.5 11.3-241.2 115-18.5 21.4-35.7 43.5-51.2 67.3l526.6-24.4c36.3-69.1 45.9-132.2 19.1-157.9-40.1-38.7-149.7 12-242 115zm-311 76.9c-48.8 78.6-63.1 152.5-33.3 181.6 39.9 38.7 148.9-8.9 240.6-112 7.7-8.9 15.5-17.9 22.6-26.8-29.2 61.4-34.5 115-10.1 138.8 39.9 38.7 148.9-8.9 240.6-112 13.7-15.5 26.8-31.6 38.7-48.2z" fill="#007dc5"/>
          <path fill="#ffffff" d="m940.5 70.2h-280v47.7h103v253.8h80.4v-254.4h96.5v-47.1zm154.3-8.9h-.6c-86.4 0-156.1 70.3-155.5 156.1s70.3 156.1 156.1 155.5 156.1-70.3 155.5-156.1c0-85.8-69.7-155.5-155.5-155.5zm-1.2 271c-42.9 0-77.4-50.6-77.4-112.6s31.6-112.6 78-112.6c42.3.6 76.8 50.6 76.8 112.6s-34.5 112.6-77.4 112.6zm885.8-271.6h-.6c-87 0-157.3 70.9-156.7 157.3 0 87 70.9 157.3 157.3 156.7s157.3-70.9 156.7-157.3c-.1-86.4-70.3-156.1-156.7-156.7zm-.6 271c-42.9 0-77.4-50.6-77.4-112.6s31.6-112.6 78-112.6c42.3.6 76.8 50.6 76.8 112.6s-34.5 112.6-77.4 112.6zm-555.2-261.5h-135.8v301.4h86.4v-69.6h48.8c159.7-.6 151.9-231.8.6-231.8zm-26.8 188.3h-22.6v-146.6h22.6c88.2 0 86.4 146.6 0 146.6z"/>
          <path d="m1749.5 335.9c-52.4 0-95.3-51.8-95.3-115.6s42.9-115.6 95.3-115.6c22 .6 42.3 9.5 58.4 24.4 0 0 20.3-40.5 19.7-40.5-26.8-20.3-61.4-25.6-97.7-25.6-87 0-157.3 70.3-157.3 157.3s70.3 157.3 157.3 157.3c28.6 0 54.8 1.2 78-11.9v-56c-7.8 15.5-37 26.2-58.4 26.2zm658.2-207.3c-20.3-50.6-50-59.6-101.3-59h-130.4v302h80.4v-257.9h41.1c19.1 0 23.2 13.7 30.4 38.7l65.5 219.2h92.3z" fill="#ffffff" /></svg>
      </animated.div>
      <animated.div style={formAnimation} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-300">Correo electrónico</label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-300">Contraseña</label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
              />
            </div>
          </div>
          
          {error && (
            <div className="text-red-600 text-sm">{error}</div>
          )}
          <div>
            <button
              type="submit"
              className="flex w-full justify-center text rounded-md bg-[#007dc5] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            >
              Iniciar sesión
            </button>
          </div>
          
        </form>
      </animated.div>
      <Footer />

      {/*  */}
    </div>
  );
}
