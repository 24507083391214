import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import Select from 'react-select';
import { uploadFilesToCloudflare, deleteFileFromCloudflare, getSignedUrlForObject } from '../utils/cloudflare';

const EditClient = () => {
  const { id } = useParams();
  const [nombre, setNombre] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [direccion, setDireccion] = useState('');
  const [delegacion, setDelegacion] = useState('');
  const [contacts, setContacts] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [series, setSeries] = useState({});
  const [addVPN, setAddVPN] = useState(false);
  const [addDICOM, setAddDICOM] = useState(false);
  const [vpnData, setVpnData] = useState('');
  const [vpnFiles, setVpnFiles] = useState([]);
  const [comentarios, setComentarios] = useState('');
  const [dicomConfig, setDicomConfig] = useState({
    scpAET: '',
    ipServidor: '',
    puertoStorage: '',
    scuAETs: [{ aet: '', modalidad: '' }],
    scpAETWL: '',
    ipServidorWL: '',
    puertoWL: '',
    scuWLs: [''],
    comentarios: '',
    files: []
  });
  const navigate = useNavigate();

  const equiposOptions = [
    { value: 'Aladdin', label: 'Aladdin' },
    { value: 'Aurora', label: 'Aurora' },
    { value: 'CA-800', label: 'CA-800' },
    { value: 'Chronos', label: 'Chronos' },
    { value: 'CL-300', label: 'CL-300' },
    { value: 'CT-1P', label: 'CT-1P' },
    { value: 'CT-800', label: 'CT-800' },
    { value: 'CV-5000S', label: 'CV-5000S' },
    { value: 'DC3', label: 'DC3' },
    { value: 'DC4', label: 'DC4' },
    { value: 'Henson 9000', label: 'Henson 9000' },
    { value: 'KR-1', label: 'KR-1' },
    { value: 'KR-1W', label: 'KR-1W' },
    { value: 'KR-800', label: 'KR-800' },
    { value: 'OCT 2000', label: 'OCT 2000' },
    { value: 'OCT Maestro', label: 'OCT Maestro' },
    { value: 'OCT Maestro 2', label: 'OCT Maestro 2' },
    { value: 'OCT Triton', label: 'OCT Triton' },
    { value: 'Myah', label: 'Myah' },
    { value: 'NW300', label: 'NW300' },
    { value: 'NW400', label: 'NW400' },
    { value: 'NW500', label: 'NW500' },
    { value: 'SP1P', label: 'SP1P' },
    { value: 'Solos', label: 'Solos' },
    { value: 'TRC50DX/EX', label: 'TRC50DX/EX' },
    { value: 'TRC-NW8', label: 'TRC-NW8' },
    { value: 'TRK-1P', label: 'TRK-1P' },
    { value: 'TRK-2P', label: 'TRK-2P' },
  ];

  const delegacionOptions = [
    { value: 'Andalucía', label: 'Andalucía' },
    { value: 'Baleares', label: 'Baleares' },
    { value: 'Canarias', label: 'Canarias' },
    { value: 'Cataluña', label: 'Cataluña' },
    { value: 'Extremadura', label: 'Extremadura' },
    { value: 'Galicia', label: 'Galicia' },
    { value: 'Getxo', label: 'Getxo' },
    { value: 'León', label: 'León' },
    { value: 'Mancha', label: 'La Mancha' },
    { value: 'Madrid', label: 'Madrid' },
    { value: 'Murcia', label: 'Murcia' },
    { value: 'Valencia', label: 'Valencia' },
    { value: 'Zaragoza', label: 'Zaragoza' },
  ];

  useEffect(() => {
    if (id && id !== 'undefined') {
      fetchCliente();
    }
  }, [id]);

  const fetchCliente = async () => {
    try {
      const { data: clientData, error: clientError } = await supabase
        .from('clientes')
        .select('*')
        .eq('id', id)
        .single();

      if (clientError) throw clientError;

      const { data: vpnData, error: vpnError } = await supabase
        .from('vpn_config')
        .select('*')
        .eq('cliente_id', id);

      if (vpnError) throw vpnError;

      const { data: dicomData, error: dicomError } = await supabase
        .from('dicom_config')
        .select('*')
        .eq('cliente_id', id);

      if (dicomError) throw dicomError;

      const vpnDataWithSignedUrls = await Promise.all(vpnData.map(async (vpn) => {
        const documentos = vpn.documentos || [];
        const signedDocuments = await Promise.all(documentos.map(async (doc) => {
          const signedUrl = await getSignedUrlForObject(process.env.REACT_APP_R2_BUCKET_NAME, doc.key);
          return { ...doc, signedUrl };
        }));
        return { ...vpn, documentos: signedDocuments };
      }));

      const dicomDataWithSignedUrls = await Promise.all(dicomData.map(async (dicom) => {
        const documentos = dicom.documentos || [];
        const signedDocuments = await Promise.all(documentos.map(async (doc) => {
          const signedUrl = await getSignedUrlForObject(process.env.REACT_APP_R2_BUCKET_NAME, doc.key);
          return { ...doc, signedUrl };
        }));
        return { ...dicom, documentos: signedDocuments };
      }));

      setNombre(clientData.nombre || '');
      setCiudad(clientData.ciudad || '');
      setCodigoPostal(clientData.codigoPostal || '');
      setDireccion(clientData.direccion || '');
      setComentarios(clientData.comentarios || '');
      setDelegacion(delegacionOptions.find(option => option.value === clientData.delegacion) || '');
      setContacts(clientData.contacto || []);
      setEquipos(clientData.equipos.map(equipo => equiposOptions.find(option => option.value === equipo.id) || { value: equipo.id, label: equipo.nombre }) || []);
      setSeries((clientData.equipos || []).reduce((acc, equipo) => ({ ...acc, [equipo.id]: equipo.numeroSerie }), {}));

      if (vpnDataWithSignedUrls.length > 0) {
        const vpn = vpnDataWithSignedUrls[0];
        setAddVPN(true);
        setVpnData(vpn.configuracion || '');
        setVpnFiles(vpn.documentos || []);
      }

      if (dicomDataWithSignedUrls.length > 0) {
        const dicom = dicomDataWithSignedUrls[0];
        setAddDICOM(true);
        setDicomConfig({
          ...dicom.configuracion,
          files: dicom.documentos || [],
        });
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const uploadedFiles = await uploadFilesToCloudflare([...vpnFiles, ...dicomConfig.files]);

        const equiposWithSeries = equipos.map(equipo => ({
            id: equipo.value,
            nombre: equipo.label,
            numeroSerie: series[equipo.value] || ''
        }));

        const { data: clienteData, error: clienteError } = await supabase
            .from('clientes')
            .update({
                nombre, 
                ciudad, 
                codigoPostal,
                direccion,
                comentarios,
                delegacion: delegacion.value,
                equipos: equiposWithSeries,
                contacto: contacts.map(contact => ({ email: contact.email, telefono: contact.telefono, nombre: contact.nombre }))
            })
            .eq('id', id)
            .select();

        if (clienteError) {
            console.error(clienteError);
            return;
        }

        const clienteId = clienteData[0].id;

        if (addVPN) {
            const { error: vpnError } = await supabase
                .from('vpn_config')
                .upsert({
                    cliente_id: clienteId,
                    configuracion: vpnData,
                    documentos: uploadedFiles.filter(file => vpnFiles.some(f => f.name === file.originalName)),
                    comentarios: comentarios
                }, { onConflict: ['cliente_id'] });

            if (vpnError) {
                console.error(vpnError);
            }
        }

        if (addDICOM) {
            const { error: dicomError } = await supabase
                .from('dicom_config')
                .upsert({
                    cliente_id: clienteId,
                    configuracion: dicomConfig,
                    documentos: uploadedFiles.filter(file => dicomConfig.files.some(f => f.name === file.originalName)),
                    comentarios: dicomConfig.comentarios
                }, { onConflict: ['cliente_id'] });

            if (dicomError) {
                console.error(dicomError);
            }
        }

        navigate('/dashboard');
    } catch (error) {
        console.error("Error uploading files:", error);
    }
};

  

  const handleSerieChange = (equipo, numeroSerie) => {
    setSeries({
      ...series,
      [equipo.value]: numeroSerie
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setDicomConfig({
        ...dicomConfig,
        files: [...dicomConfig.files, ...selectedFiles]
    });
};

const handleVPNFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setVpnFiles([...vpnFiles, ...selectedFiles]);
};


  const handleAddContact = () => {
    setContacts([...contacts, { email: '', telefono: '', nombre: '' }]);
  };

  const handleRemoveContact = (index) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const handleAddSCUAET = () => {
    setDicomConfig({
      ...dicomConfig,
      scuAETs: [...dicomConfig.scuAETs, { aet: '', modalidad: '' }]
    });
  };

  const handleRemoveSCUAET = (index) => {
    setDicomConfig({
      ...dicomConfig,
      scuAETs: dicomConfig.scuAETs.filter((_, i) => i !== index)
    });
  };

  const handleAddSCUWL = () => {
    setDicomConfig({
      ...dicomConfig,
      scuWLs: [...dicomConfig.scuWLs, '']
    });
  };

  const handleRemoveSCUWL = (index) => {
    setDicomConfig({
      ...dicomConfig,
      scuWLs: dicomConfig.scuWLs.filter((_, i) => i !== index)
    });
  };

  const handleDicomConfigChange = (field, value, index = null, subfield = null) => {
    if (index !== null) {
      if (subfield) {
        const updatedSCUs = [...dicomConfig[field]];
        updatedSCUs[index][subfield] = value;
        setDicomConfig({
          ...dicomConfig,
          [field]: updatedSCUs
        });
      } else {
        const updatedSCUs = [...dicomConfig[field]];
        updatedSCUs[index] = value;
        setDicomConfig({
          ...dicomConfig,
          [field]: updatedSCUs
        });
      }
    } else {
      setDicomConfig({
        ...dicomConfig,
        [field]: value
      });
    }
  };

  const handleDeleteFile = async (key, type) => {
    try {
        await deleteFileFromCloudflare(key);

        if (type === 'vpn') {
            const updatedVpnFiles = vpnFiles.filter(file => file.key !== key);
            setVpnFiles(updatedVpnFiles);
            await supabase
                .from('vpn_config')
                .update({ documentos: updatedVpnFiles })
                .eq('cliente_id', id);
        } else if (type === 'dicom') {
            const updatedDicomFiles = dicomConfig.files.filter(file => file.key !== key);
            setDicomConfig({
                ...dicomConfig,
                files: updatedDicomFiles
            });
            await supabase
                .from('dicom_config')
                .update({ documentos: updatedDicomFiles })
                .eq('cliente_id', id);
        }
    } catch (error) {
        console.error('Error deleting file:', error);
    }
};

  

  const renderFiles = (files, type) => {
    return files.map((file, index) => (
      <li key={index} className="flex items-center">
        <a href={file.signedUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 flex-1">
          {file.originalName}
        </a>
        <button
          type="button"
          onClick={() => handleDeleteFile(file.key, type)}
          className="ml-2 text-red-500 hover:text-red-700"
        >
          Borrar
        </button>
      </li>
    ));
  };

  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      {/* Barra de Navegación */}
      <nav className="flex mb-4 px-6 " aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <a
              href="Home"
              className="inline-flex items-center text-sm font-medium text-gray-400 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Inicio
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="/Dashboard"
                className="ms-1 text-sm font-medium text-gray-400 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Clientes
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 text-gray-400 mx-1 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="/Create-Client"
                className="ms-1 text-sm font-medium text-gray-400 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Editar Cliente
              </a>
            </div>
          </li>
        </ol>
      </nav>

      {/* Formulario de Creación de Cliente */}
      <div className="flex justify-center items-center  h-screen bg-gray-900 p-5">
        <form onSubmit={handleSubmit} className="max-w-7xl w-full bg-gray-800 p-8 rounded-lg shadow-lg grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <h2 className="col-span-full text-2xl font-bold mb-6 text-white text-center">Editar Cliente</h2>

          {/* Sección 1: Información del Cliente */}
          <div className="col-span-full lg:col-span-1 bg-gray-700 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-white">Información del Cliente</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Nombre</label>
              <input
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
                className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
              />
            </div>
            {showMore && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2 text-white">Ciudad</label>
                  <input
                    value={ciudad}
                    onChange={(e) => setCiudad(e.target.value)}
                    className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2 text-white">Código Postal</label>
                  <input
                    value={codigoPostal}
                    onChange={(e) => setCodigoPostal(e.target.value)}
                    className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2 text-white">Dirección</label>
                  <input
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                  />
                </div>
              </>
            )}
            <button
              type="button"
              onClick={() => setShowMore(!showMore)}
              className="w-full bg-blue-500 text-white py-2 mb-5 px-4 rounded hover:bg-blue-600 transition duration-300 text-sm mt-2"
            >
              {showMore ? 'Ver menos' : 'Ver más'}
            </button>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Delegación</label>
              <Select
                options={delegacionOptions}
                value={delegacion}
                onChange={setDelegacion}
                className="text-gray-900 text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Equipos Asignados</label>
              <Select
                isMulti
                options={equiposOptions}
                value={equipos}
                onChange={setEquipos}
                className="text-gray-900 text-sm"
              />
            </div>
            {equipos.map(equipo => (
              <div key={equipo.value} className="mb-4">
                <label className="block text-sm font-medium mb-2 text-white">Número de Serie para {equipo.label}</label>
                <input
                  value={series[equipo.value] || ''}
                  onChange={(e) => handleSerieChange(equipo, e.target.value)}
                  className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                />
              </div>
            ))}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Comentarios</label>
              <textarea
                value={comentarios}
                onChange={(e) => setComentarios(e.target.value)}
                className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
              />
            </div>
          </div>

          {/* Sección 2: Contactos */}
          <div className="col-span-full lg:col-span-1 bg-gray-700 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-white">Contactos</h3>
            {contacts.length === 0 && (
              <button
                type="button"
                onClick={handleAddContact}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 text-sm"
              >
                Añadir Contacto
              </button>
            )}
            {contacts.map((contact, index) => (
              <div key={index} className="mb-4">
                <label className="block text-sm font-medium mb-2 text-white">Email del Contacto {index + 1}</label>
                <input
                  type="email"
                  value={contact.email}
                  onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                  className="w-full px-2 py-1 mb-2 border border-gray-600 rounded-md text-gray-900 text-sm"
                />
                <label className="block text-sm font-medium mb-2 text-white">Teléfono del Contacto {index + 1}</label>
                <input
                  value={contact.telefono}
                  onChange={(e) => handleContactChange(index, 'telefono', e.target.value)}
                  className="w-full px-2 py-1 mb-2 border border-gray-600 rounded-md text-gray-900 text-sm"
                />
                <label className="block text-sm font-medium mb-2 text-white">Nombre del Contacto {index + 1}</label>
                <input
                  value={contact.nombre}
                  onChange={(e) => handleContactChange(index, 'nombre', e.target.value)}
                  className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveContact(index)}
                  className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300 text-sm mt-2"
                >
                  Borrar Contacto
                </button>
              </div>
            ))}
            {contacts.length > 0 && (
              <button
                type="button"
                onClick={handleAddContact}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 text-sm mt-2"
              >
                Añadir Contacto
              </button>
            )}
          </div>

          {/* Sección 3: Configuraciones de VPN y DICOM */}
          <div className="col-span-full lg:col-span-1 bg-gray-700 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-white">Configuraciones Adicionales</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Agregar Datos VPN</label>
              <input
                type="checkbox"
                checked={addVPN}
                onChange={(e) => setAddVPN(e.target.checked)}
                className="mr-2"
              />
            </div>
            {addVPN && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2  text-white">Datos de VPN</label>
                <textarea
                  value={vpnData}
                  onChange={(e) => setVpnData(e.target.value)}
                  className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                />
                <label className="block text-sm font-medium mb-1 mt-5 text-white">Documentación Adjunta</label>
                <ul>
                  {renderFiles(vpnFiles, 'vpn')}
                </ul>
                <input
                  type="file"
                  multiple
                  onChange={handleVPNFileChange}
                  className="block w-full text-gray-900 border mt-5 border-gray-600 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                />
              </div>
            )}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-white">Agregar Datos DICOM</label>
              <input
                type="checkbox"
                checked={addDICOM}
                onChange={(e) => setAddDICOM(e.target.checked)}
                className="mr-2"
              />
            </div>
            {addDICOM && (
              <div>
                <h4 className="text-lg font-semibold mb-4 text-white">DICOM Configuration</h4>
                <div className="flex justify-between mb-4">
                  <div className="w-1/2 pr-2">
                    <h5 className="text-md font-semibold mb-2 text-white">Storage</h5>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">SCP AET</label>
                      <input
                        value={dicomConfig.scpAET}
                        onChange={(e) => handleDicomConfigChange('scpAET', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">IP del Servidor</label>
                      <input
                        value={dicomConfig.ipServidor}
                        onChange={(e) => handleDicomConfigChange('ipServidor', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">Puerto de Storage</label>
                      <input
                        value={dicomConfig.puertoStorage}
                        onChange={(e) => handleDicomConfigChange('puertoStorage', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    {dicomConfig.scuAETs.map((scu, index) => (
                      <div key={index} className="mb-2">
                        <label className="block text-sm font-medium mb-2 text-white">SCU AET {index + 1}</label>
                        <input
                          value={scu.aet}
                          onChange={(e) => handleDicomConfigChange('scuAETs', e.target.value, index, 'aet')}
                          className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm mb-2"
                        />
                        <label className="block text-sm font-medium mb-2 text-white">Modalidad</label>
                        <input
                          value={scu.modalidad}
                          onChange={(e) => handleDicomConfigChange('scuAETs', e.target.value, index, 'modalidad')}
                          className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveSCUAET(index)}
                          className="w-full bg-red-500 mt-3 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300 mb-2 text-sm"
                        >
                          Borrar SCU AET
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddSCUAET}
                      className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mb-4 text-sm"
                    >
                      Añadir SCU AET
                    </button>
                  </div>

                  <div className="w-1/2 pl-2">
                    <h5 className="text-md font-semibold mb-2 text-white">Worklist</h5>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">SCP AET de WL</label>
                      <input
                        value={dicomConfig.scpAETWL}
                        onChange={(e) => handleDicomConfigChange('scpAETWL', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">IP del Servidor WL</label>
                      <input
                        value={dicomConfig.ipServidorWL}
                        onChange={(e) => handleDicomConfigChange('ipServidorWL', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="block text-sm font-medium mb-2 text-white">Puerto de la WL</label>
                      <input
                        value={dicomConfig.puertoWL}
                        onChange={(e) => handleDicomConfigChange('puertoWL', e.target.value)}
                        className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                      />
                    </div>
                    {dicomConfig.scuWLs.map((aet, index) => (
                      <div key={index} className="mb-2">
                        <label className="block text-sm font-medium mb-2 text-white">SCU WL {index + 1}</label>
                        <input
                          value={aet}
                          onChange={(e) => handleDicomConfigChange('scuWLs', e.target.value, index)}
                          className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveSCUWL(index)}
                          className="w-full bg-red-500 mt-3 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300 mb-2 text-sm"
                        >
                          Borrar SCU WL
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddSCUWL}
                      className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mb-4 text-sm"
                    >
                      Añadir SCU WL
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2 text-white">Comentarios</label>
                  <textarea
                    value={dicomConfig.comentarios}
                    onChange={(e) => handleDicomConfigChange('comentarios', e.target.value)}
                    className="w-full px-2 py-1 border border-gray-600 rounded-md text-gray-900 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2 text-white">Adjuntar Documentación</label>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="block w-full text-gray-900 border border-gray-600 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                  <ul>
                    {renderFiles(dicomConfig.files, 'dicom')}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="col-span-3 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Guardar Cambios
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditClient;