import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useNavigate, useParams } from 'react-router-dom';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    // Buscar el usuario por el token de restablecimiento
    const { data: user, error: userError } = await supabase
      .from('logins')
      .select('*')
      .eq('reset_token', token)
      .single();

    if (userError || !user) {
      setError('Token de restablecimiento no válido.');
      return;
    }

    // Actualizar la contraseña y eliminar el token
    const { error: updateError } = await supabase
      .from('logins')
      .update({ password: newPassword, reset_token: null, reset_token_expiry: null })
      .eq('id', user.id);

    if (updateError) {
      setError('Error al actualizar la contraseña.');
      return;
    }

    setMessage('Contraseña actualizada correctamente.');
    setTimeout(() => navigate('/login'), 3000); // Navegar a la página de inicio de sesión después de 3 segundos
  };

  return (
    <div className="flex max-h-screen flex-1 flex-col justify-center items-center justify-items-center px-6 py-52 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-300">
          Cambio de Contraseña
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleChangePassword}>
          <div>
            <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-gray-300">
              Nueva Contraseña
            </label>
            <div className="mt-2">
              <input
                id="new-password"
                name="new-password"
                type="password"
                autoComplete="new-password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-300">
              Confirmar Nueva Contraseña
            </label>
            <div className="mt-2">
              <input
                id="confirm-password"
                name="confirm-password"
                type="password"
                autoComplete="confirm-password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {error && (
            <div className="text-red-600 text-sm">{error}</div>
          )}
          {message && (
            <div className="text-green-600 text-sm">{message}</div>
          )}
          <div>
            <button
              type="submit"
              className="flex w-full justify-center text rounded-md bg-[#007dc5] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
            >
              Cambiar Contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
