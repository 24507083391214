import React, { useEffect, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid';

const UsersDataGrid = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUsers] = useState(null);
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const userRole = sessionStorage.getItem('userRole');

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    const { data, error } = await supabase.from('logins').select('*');
    if (error) {
      console.error(error);
    } else {
      setUsers(data);
    }
  };

  const handleCreateUser = () => {
    navigate('/CreateUser');
  };

  const handleEditUser = () => {
    if (selectedUser) {
      navigate(`/edit-user/${selectedUser.id}`);
    } else {
      alert('Seleccione un usuario para editar');
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUser) {
      const confirmed = window.confirm('¿Estás seguro de que deseas eliminar este usuario?');
      if (confirmed) {
        const { error } = await supabase.from('logins').delete().eq('id', selectedUser.id);
        if (error) {
          console.error(error);
        } else {
          fetchUsuarios();
        }
      }
    } else {
      alert('Seleccione un usuario para eliminar');
    }
  };

  const handleResetPassword = async () => {
    if (selectedUser) {
      const confirmed = window.confirm('¿Estás seguro de que deseas resetear la contraseña de este usuario?');
      if (confirmed) {
        const { error } = await supabase.from('logins').update({ ultima_vez_login: null }).eq('id', selectedUser.id);
        if (error) {
          console.error(error);
        } else {
          fetchUsuarios();
        }
      }
    } else {
      alert('Seleccione un usuario para resetear la contraseña');
    }
  };

  const columns = [
    { 
      headerName: 'Nombre del Usuario', 
      field: 'nombre', 
      cellStyle: { fontSize: '16px'}, 
      resizable: true, 
      width: 450,
      filter: 'agTextColumnFilter',
      colId: 'nombre',
    },
    { 
      headerName: 'Email', 
      field: 'email', 
      resizable: true, 
      width: 350,
      filter: 'agTextColumnFilter',
      colId: 'email',
    },
    
    {
        headerName: 'Fecha de Creación',
        field: 'fecha_creacion',
        resizable: true,
        width: 350,
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          if (!params.value) return '';
          const date = new Date(params.value);
          return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric'});
        },
        colId: 'fecha_creacion',
      },
      {
        headerName: 'Última vez logeado',
        field: 'ultima_vez_login',
        resizable: true,
        width: 350,
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          if (!params.value) return '';
          const date = new Date(params.value);
          return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric'}, { hour: '2-digit', minute: '2-digit', second: '2-digit'});
        },
        colId: 'ultima_vez_login',
      },
    { 
      headerName: 'Rol',	 
      field: 'rol', 
      width: 350,
      resizable: true, 
      filter: 'agTextColumnFilter',
      colId: 'rol',
    }
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const updateColumnVisibility = () => {
      if (window.innerWidth < 640) { // sm breakpoint
        params.api.setColumnsVisible(['email', 'ultima_vez_login', 'password', 'fecha_creacion', 'email' ], false);
      } else {
        params.api.setColumnsVisible(['email', 'ultima_vez_login', 'password', 'fecha_creacion', 'email'], true);
      }
      params.api.sizeColumnsToFit();
    };
    updateColumnVisibility();
    window.addEventListener('resize', updateColumnVisibility);
    return () => window.removeEventListener('resize', updateColumnVisibility);
  }, []);

  return (
    <div className='ag-theme-quartz-dark h-full flex-grow'>
      <div className="mb-4 flex justify-between">
        <div>
          <button
            onClick={handleCreateUser}
            className="bg-blue-600 text-white p-2 rounded mr-2 hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Crear Usuario
          </button>
        </div>
        <div className="flex space-x-2">
            <>
              <button
                onClick={handleEditUser}
                className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Editar Usuario
              </button>
              <button
                onClick={handleResetPassword}
                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Resetear Contraseña
              </button>
              <button
                onClick={handleDeleteUser}
                className="bg-red-600 text-white p-2 rounded hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Eliminar Usuario
              </button>
            </>
        </div>
      </div>
      <AgGridReact
        rowData={users}
        columnDefs={columns}
        pagination={false}
        paginationPageSize={20}
        rowSelection="single"
        onRowSelected={(event) => setSelectedUsers(event.api.getSelectedRows()[0])}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default UsersDataGrid;
