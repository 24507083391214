import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { uploadFilesToCloudflare } from '../utils/cloudflare';

Quill.register('modules/imageResize', ImageResize);

const CreatePost = ({ setCreatingPost, fetchPosts }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');

  const userId = sessionStorage.getItem('userId'); // Obtén el userId del sessionStorage
  const userName = sessionStorage.getItem('userName'); // Obtén el userName del sessionStorage

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const { data, error } = await supabase.from('posts').select('category');
    if (error) {
      console.error('Error fetching categories:', error);
    } else {
      // Obtener categorías únicas y capitalizar la primera letra
      const uniqueCategories = [...new Set(data.map(item => item.category))]
        .map(category => category.charAt(0).toUpperCase() + category.slice(1));
      
      setCategories(uniqueCategories);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedCategory = (newCategory ? newCategory : category).toLowerCase();

    try {
      const { data: postData, error: postError } = await supabase
        .from('posts')
        .insert([{ title, content, category: selectedCategory, login_id: userId }]) // Usa el userId del sessionStorage
        .select()
        .single();

      if (postError) {
        console.error('Error creating post:', postError);
        return;
      }

      if (!postData || !postData.id) {
        console.error('No post data returned:', postData);
        return;
      }

      const postId = postData.id;

      if (files.length > 0) {
        const uploadedFiles = await uploadFilesToCloudflare(files);

        const mediaData = uploadedFiles.map(file => ({
          post_id: postId,
          url: JSON.stringify({ originalName: file.originalName, key: file.key, url: file.url })
        }));

        const { error: mediaError } = await supabase
          .from('media')
          .insert(mediaData);

        if (mediaError) {
          console.error('Error inserting media data:', mediaError);
          return;
        }
      }

      setCreatingPost(false);
      fetchPosts();
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  const formats = [
    'header', 'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video'
  ];

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-gray-800 rounded-lg shadow-md">
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Título</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-2"
          required
        />
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Contenido</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
          className="bg-gray-700 text-white rounded-md"
        />
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Categoría</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-2"
          disabled={newCategory !== ''}
        >
          <option value="" disabled>Selecciona una categoría</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat.toLowerCase()}>
              {cat}
            </option>
          ))}
        </select>
        <div className="mt-2">
          <label className="block text-lg font-medium text-gray-300">O crea una nueva categoría</label>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-2"
            placeholder="Nueva categoría"
          />
        </div>
      </div>
      <div className="space-y-2">
        <label className="block text-lg font-medium text-gray-300">Archivos Adicionales</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mt-1 block w-full rounded-md bg-gray-700 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2"
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="px-6 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-lg"
        >
          Crear Post
        </button>
      </div>
    </form>
  );
};

export default CreatePost;
