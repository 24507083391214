import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { HandThumbUpIcon, UserIcon, ArrowDownTrayIcon, PencilIcon } from '@heroicons/react/20/solid';
import { getSignedUrlForObject } from '../utils/awsClients';
import EditPost from './EditPost';
import Loader from './Loader'; // Importa el componente Loader

const PostView = ({ postId, fetchPosts, userRole }) => {
  const [post, setPost] = useState(null);
  const [postMedia, setPostMedia] = useState([]);
  const [postAuthor, setPostAuthor] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [postLikers, setPostLikers] = useState([]);
  const [commentLikers, setCommentLikers] = useState([]);
  const [editingPost, setEditingPost] = useState(false);
  const [loading, setLoading] = useState(true); // Estado de carga
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    fetchPostDetails(postId);
  }, [postId]);

  const fetchPostDetails = async (postId) => {
    const { data, error } = await supabase
      .from('posts')
      .select(`
        *,
        logins ( nombre )
      `)
      .eq('id', postId)
      .single();
    if (error) {
      console.error(error);
    } else {
      setPost(data);
      setPostAuthor(data.logins.nombre);
      fetchPostMedia(postId);
      fetchComments(postId);
      fetchPostLikers(postId);
      setLoading(false); // Termina la carga
    }
  };

  const fetchPostMedia = async (postId) => {
    const { data, error } = await supabase
      .from('media')
      .select('*')
      .eq('post_id', postId);
    if (error) {
      console.error(error);
    } else {
      const mediaWithSignedUrls = await Promise.all(data.map(async (file) => {
        const mediaInfo = JSON.parse(file.url);
        try {
          const signedUrl = await getSignedUrlForObject(process.env.REACT_APP_R2_BUCKET_NAME, mediaInfo.key);
          return { ...file, ...mediaInfo, signedUrl };
        } catch (error) {
          console.error('Error generating signed URL:', error);
          return { ...file, ...mediaInfo, signedUrl: '#' };
        }
      }));
      setPostMedia(mediaWithSignedUrls);
    }
  };

  const fetchComments = async (postId) => {
    const { data, error } = await supabase
      .from('comments')
      .select('*, logins (nombre)')
      .eq('post_id', postId);
    if (error) {
      console.error(error);
    } else {
      setComments(data);
    }
  };

  const fetchPostLikers = async (postId) => {
    const { data, error } = await supabase
      .from('post_likes')
      .select('*, logins (nombre)')
      .eq('post_id', postId);
    if (error) {
      console.error(error);
    } else {
      setPostLikers(data);
    }
  };

  const fetchCommentLikers = async (commentId) => {
    const { data, error } = await supabase
      .from('comment_likes')
      .select('*, logins (nombre)')
      .eq('comment_id', commentId);
    if (error) {
      console.error(error);
    } else {
      setCommentLikers(data);
    }
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    if (!post) return;

    const { data, error } = await supabase
      .from('comments')
      .insert([{ post_id: post.id, login_id: userId, content: newComment }]);

    if (error) {
      console.error('Error adding comment:', error);
    } else {
      setNewComment('');
      fetchComments(post.id);
    }
  };

  const handlePostLike = async (postId) => {
    const { data, error } = await supabase
      .rpc('increment_post_likes', { post_id_arg: postId, user_id_arg: userId });

    if (error) {
      console.error('Error liking post:', error);
    } else {
      fetchPostDetails(postId);
      fetchPostLikers(postId);
    }
  };

  const handleCommentLike = async (commentId) => {
    const { data, error } = await supabase
      .rpc('increment_comment_likes', { comment_id_arg: commentId, user_id_arg: userId });

    if (error) {
      console.error('Error liking comment:', error);
    } else {
      fetchComments(post.id);
      fetchCommentLikers(commentId);
    }
  };

  const handleEditPostClick = () => {
    setEditingPost(true);
  };

  return (
    <div>
      {loading ? (
        <Loader /> // Muestra el loader mientras se cargan los detalles del post
      ) : editingPost ? (
        <EditPost post={post} setEditingPost={setEditingPost} fetchPosts={fetchPosts} />
      ) : post ? (
        <>
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
              <p className="text-sm text-gray-400 mb-4">Fecha de creación: {new Date(post.created_at).toLocaleDateString()}</p>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => handlePostLike(post.id)}
                className={` hover:text-blue-400 flex items-center mr-4 ${postLikers.some(liker => liker.login_id === userId) ? 'text-blue-500' : 'text-gray-400'}`}
              >
                <HandThumbUpIcon className="h-6 w-6 mr-1" />
                {post.likes_count}
              </button>
              {(userRole === 'Administrador' || userRole === 'Publicador') && (
              <button
                onClick={handleEditPostClick}
                className=" text-gray-400 p-2 rounded-full hover:text-blue-400"
              >
                <PencilIcon className="h-6 w-6" />
              </button>)}
            </div>
          </div>
          <p className="text-sm text-gray-400 mb-4">Autor: {postAuthor}</p>
          <div
            className="quill-content mb-4"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></div>
          {postMedia.length > 0 && (
            <div className="mb-4">
              <h3 className="text-xl font-bold mb-2">Archivos adjuntos:</h3>
              <ul>
                {postMedia.map((media, index) => (
                  <li key={index} className="flex items-center mb-2">
                    <a href={media.signedUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 flex-1">
                      {media.originalName}
                    </a>
                    <a href={media.signedUrl} target="_blank" rel="noopener noreferrer" className="ml-2 text-gray-400 hover:text-gray-600">
                      <ArrowDownTrayIcon className="w-5 h-5" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Comentarios</h2>
            <ul className="space-y-2">
              {comments.map(comment => (
                <li key={comment.id} className="bg-gray-800 p-4 rounded-md flex justify-between items-center">
                  <div>
                    <p>{comment.content}</p>
                    <p className="text-sm text-gray-400">- {comment.logins.nombre}</p>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => handleCommentLike(comment.id)}
                      className={`flex items-center ${commentLikers.some(liker => liker.login_id === userId) ? 'text-blue-500' : 'text-gray-400'}`}
                    >
                      <HandThumbUpIcon className="h-5 w-5 mr-1" />
                      {comment.likes_count || 0}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <form onSubmit={handleCommentSubmit} className="mt-4">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Escribe un comentario..."
                required
                className="w-full p-2 rounded-md bg-gray-800 text-white border-gray-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              ></textarea>
              <button
                type="submit"
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Agregar Comentario
              </button>
            </form>
          </div>
          {postLikers.length > 0 && (
            <div className="mt-4 space-y-2">
              <h3 className="text-xl font-bold mb-2">Le gusta a: </h3>
              <ul>
                {postLikers.map((liker, index) => (
                  <li key={index} className="flex items-center mb-5  space-x-2">
                    <UserIcon className="h-5 w-5 text-gray-400" />
                    <span>{liker.logins.nombre}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <h1 className="text-3xl font-bold mb-4">Selecciona un post para ver su contenido o crea uno nuevo</h1>
      )}
    </div>
  );
};

export default PostView;
