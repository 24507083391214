import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, adminOnly }) => {
  const isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';
  const userRole = sessionStorage.getItem('userRole');

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (adminOnly && userRole !== 'Administrador') {
    return <Navigate to="/Home" />;
  }

  return children;
};

export default PrivateRoute;
